@import "./variables.scss";

body {
    margin: 0px;
    button {
        font-size: 1.2em;
    }

    overflow: hidden;
}

.dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 3fr;
    height: 100vh;
    width: 100vw;

    input:not(.box-ip) {
        border: none;
        text-align: center;
    }
    
}
